import { graphql } from 'gatsby'
import React from 'react'

import Article from '../screens/article'

const MarketPost = (props) => {
  const article = props.data.wpMarketUpdate
  return <Article {...props} article={article} category="market-updates" />
}

export default MarketPost

export const pageQuery = graphql`
  query MarketById($slug: String!) {
    wpMarketUpdate(slug: { eq: $slug }) {
      id
      title
      content
      date(formatString: "MMM DD, YYYY")
      dateGmt
      slug
      status
      excerpt
      nodeType
      tags {
        nodes {
          name
          slug
        }
      }
      categories {
        nodes {
          name
          slug
          description
          id
        }
      }
      acf: acfMarketUpdates {
        articleExcerpt
      }
      featuredImage {
        node {
          caption
          sourceUrl
        }
      }
    }
  }
`
